<!--
.. Added by Kishore Jalleda
.. full list of modifications at https://github.com/unstructai
.. copyright: (c) 2024 Kishore Jalleda
.. author:: Kishore Jalleda <kjalleda@gmail.com>
-->
<template>
    <div>
      <v-data-table
        :headers="headers"
        :items="items"
        @click:row="handleRowClick"
      >
        <template v-slot:item.project.name="{ item }">
          <v-chip small :color="item.project.color" text-color="white">
            {{ item.project.name }}
          </v-chip>
        </template>
        <template v-slot:item.id="{ item }">
          <v-icon
            :class="{'copied-icon': copiedItemId === item.id}"
            small
            left
            @click="copyToClipboard(item.id)"
          >
            mdi-content-copy
          </v-icon>
          {{ item.id }}
        </template>
        <template v-slot:item.commit_url="{ item }">
          <a :href="item.commit_url" target="_blank">{{ item.commit_url }}</a>
        </template>
        <template v-slot:item.commit_summary="{ item }">
          <commit-ai-summary-dialog :summary="item.commit_summary" />
        </template>
        <template v-slot:item.commit_raw="{ item }">
          <commit-raw-dialog :commit_raw="item.commit_raw" />
        </template>
        <template v-slot:item.commit_date="{ item }">
          {{ item.commit_date | formatRelativeDate }}
        </template>
        <template v-slot:item.repository_url="{ item }">
          <a :href="item.repository_url" target="_blank">{{ item.repository_url }}</a>
        </template>
        <template v-slot:item.data-table-actions="{ item }">
          <v-menu bottom left>
            <template v-slot:activator="{ on }">
              <v-btn icon v-on="on">
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item :to="{ name: 'CommitTableEdit', params: { name: item.name } }">
                <v-list-item-title>View / Edit</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
    </div>
  </template>

  <script>
  import { mapActions } from "vuex"
  import CommitAiSummaryDialog from '@/commit/CommitAiSummaryDialog.vue'
  import CommitRawDialog from '@/commit/CommitRawDialog.vue';
  export default {
    name: "QuerySummaryTable",

    components: {
      CommitAiSummaryDialog,
      CommitRawDialog,
    },
    data() {
      return {
        headers: [
          { text: "Commit Message", value: "commit_message", sortable: true },
          { text: "ID", value: "id", sortable: true },
          { text: "Project", value: "project.name", sortable: false },
          { text: "Commit URL", value: "commit_url", sortable: false },
          { text: "Commit Summary", value: "commit_summary", sortable: false },
          { text: "Commit Raw", value: "commit_raw", sortable: false },
          { text: "Commit Date", value: "commit_date", sortable: true },
          { text: "Commit Author", value: "commit_author", sortable: true },
          { text: "Repository", value: "repository_name", sortable: false },
          { text: "Repository URL", value: "repository_url", sortable: false },
          { text: "Repository Branch", value: "repository_branch", sortable: false },
          { text: "Source", value: "source", sortable: false },
          {
            text: "",
            value: "data-table-actions",
            sortable: false,
            align: "end",
          },
        ],
        copiedItemId: null,
      }
    },

    props: {
      items: {
        default: function () {
          return []
        },
        type: Array,
      },
      loading: {
        default: function () {
          return false
        },
        type: [String, Boolean],
      },
    },

    methods: {
      ...mapActions("query", ["removeShow"]),
      copyToClipboard(text) {
        navigator.clipboard.writeText(text)
        this.copiedItemId = text
        setTimeout(() => {
          this.copiedItemId = null
        }, 2000)
      },
      handleRowClick(item) {
        this.$emit("row-clicked", item)
      },
    },
  }
  </script>

  <style scoped>
  .copied-icon {
    color: green;
  }

<!--
.. Modified by Kishore Jalleda
.. full list of modifications at https://github.com/unstructai
.. copyright: (c) 2023 Kishore Jalleda
.. author:: Kishore Jalleda <kjalleda@gmail.com>
-->
<template>
  <div class="pl-2">
    <v-chip>
      <v-icon :color="priorityColor" left>mdi-flag</v-icon>
      {{ priorityName }}
    </v-chip>
  </div>
</template>

<script>
export default {
  name: "IncidentPriority",

  props: {
    priorityName: {
      type: String,
      required: true,
    },
    priorityColor: {
      type: String,
      required: true,
    },
  },
}
</script>

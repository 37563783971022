<template>
    <div>
      <v-data-table
        :headers="headers"
        :items="items"
        :loading="loading"
        @click:row="handleRowClick"
      >
        <!-- Add specific template slots for ECCSProject fields -->
        <template v-slot:item.id="{ item }">
          <v-icon
            :class="{'copied-icon': copiedItemId === item.id}"
            small
            left
            @click="copyToClipboard(item.id)"
          >
            mdi-content-copy
          </v-icon>
          {{ item.id }}
        </template>
        <!-- Add more custom template slots as needed -->
        <template v-slot:item.data-table-actions="{ item }">
          <v-menu bottom left>
            <template v-slot:activator="{ on }">
              <v-btn icon v-on="on">
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item :to="{ name: 'ECCSProjectEdit', params: { id: item.id } }">
                <v-list-item-title>View / Edit</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
    </div>
  </template>

  <script>
  import { mapActions } from "vuex"

  export default {
    name: "ECCSProjectSummaryTable",

    data() {
      return {
        headers: [
          { text: "ID", value: "id", align: "left", sortable: true },
          { text: "Name", value: "name", align: "left", sortable: true },
          { text: "Description", value: "description", align: "left", sortable: true },
          { text: "Created At", value: "created_at", align: "left", sortable: true },
          { text: "Updated At", value: "updated_at", align: "left", sortable: true },
          { text: "Parent ID", value: "parent_id", align: "left", sortable: true },
          { text: "", value: "data-table-actions", sortable: false, align: "end" },
        ],
        copiedItemId: null,
      }
    },

    props: {
      items: {
        default: () => [],
        type: Array,
      },
      loading: {
        default: false,
        type: [String, Boolean],
      },
    },

    methods: {
      ...mapActions("eccsProject", ["showEditSheet"]),
      copyToClipboard(text) {
        navigator.clipboard.writeText(text).then(() => {
          this.copiedItemId = text
          setTimeout(() => {
            this.copiedItemId = null
          }, 2000)
        })
      },
      handleRowClick(item) {
        this.$emit('row-clicked', item);
      },
    },
  }
  </script>

  <style scoped>
  .copied-icon {
    color: green;
  }
  </style>

<!--
.. Modified by Kishore Jalleda
.. full list of modifications at https://github.com/unstructai
.. copyright: (c) 2023 Kishore Jalleda
.. author:: Kishore Jalleda <kjalleda@gmail.com>
-->
<template>
  <div>
    <v-chip small :color="badgeColor">
      <v-icon small left>{{ getStatusIcon() }}</v-icon>
      {{ status }}
    </v-chip>

    <!-- Actions for Active and Stable statuses -->
    <template v-if="isActionableStatus">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn class="ml-1" icon v-bind="attrs" v-on="on" @click.stop="joinIncident(id)">
            <v-icon :color="joinIconColor">mdi-account-plus</v-icon>
          </v-btn>
        </template>
        <span>Join</span>
      </v-tooltip>

      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn class="ml-1" icon v-bind="attrs" v-on="on" @click.stop="subscribeToIncident(id)">
            <v-icon :color="subscribeIconColor">mdi-email-plus</v-icon>
          </v-btn>
        </template>
        <span>Subscribe</span>
      </v-tooltip>
    </template>
    <!-- Actions for Closed status, greyed out -->
    <template v-else>
      <v-btn class="ml-1" icon disabled>
        <v-icon :color="joinIconColor">mdi-account-plus</v-icon>
      </v-btn>
      <v-btn class="ml-1" icon disabled>
        <v-icon :color="subscribeIconColor">mdi-email-plus</v-icon>
      </v-btn>
    </template>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "IncidentStatus",

  props: {
    status: {
      type: String,
      required: true,
    },
    id: {
      type: Number,
      required: true,
    },
  },

  computed: {
    badgeColor() {
      return {
        Active: "error",
        Stable: "warning",
        Closed: "success",
      }[this.status];
    },
    isActionableStatus() {
      return this.status === 'Active' || this.status === 'Stable';
    },
    joinIconColor() {
      if (this.status === 'Active') {
        return 'error';
      } else if (this.status === 'Stable') {
        return 'warning';
      } else {
        return 'grey';
      }
    },
    subscribeIconColor() {
      if (this.status === 'Active') {
        return 'error';
      } else if (this.status === 'Stable') {
        return 'warning';
      } else {
        return 'grey';
      }
    },
  },

  methods: {
    ...mapActions("incident", ["joinIncident", "subscribeToIncident"]),
    getStatusIcon() {
      switch (this.status) {
        case 'Active':
          return 'mdi-domain';
        case 'Stable':
          return 'mdi-progress-check';
        case 'Closed':
          return 'mdi-lock';
        default:
          return 'mdi-network-strength-1-alert';
      }
    },
  },
};
</script>

<!--
.. Added by Kishore Jalleda
.. full list of modifications at https://github.com/unstructai
.. copyright: (c) 2024 Kishore Jalleda
.. author:: Kishore Jalleda <kjalleda@gmail.com>
-->
<template>
  <div>
    <v-text-field
      v-model="queryString"
      prepend-inner-icon="mdi-magnify"
      label="Search for IAOs..."
      clearable
      filled
      rounded
      dense
      @keyup.enter="performSearch"
      class="mb-4"
    ></v-text-field>

    <v-card outlined>
      <v-card-title class="headline">
        Search results for: "{{ query }}"
      </v-card-title>

      <v-card-text style="max-height: 60vh; overflow-y: auto;">
        <v-row>
          <v-col v-for="type in resultTypes" :key="type" cols="12" sm="6" md="4">
            <v-card outlined class="mb-4">
              <v-card-title>
                {{ type }} ({{ (results[type] && results[type].length) || 0 }})
                <v-spacer></v-spacer>
                <v-btn icon @click="toggleExpand(type)" :disabled="loading">
                  <v-icon>{{ expanded[type] ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
                </v-btn>
              </v-card-title>
              <v-expand-transition>
                <v-card-text>
                  <v-skeleton-loader
                    v-if="loading"
                    type="table"
                  ></v-skeleton-loader>
                  <template v-else>
                    <component
                      v-if="results[type] && results[type].length > 0"
                      :is="getComponentName(type)"
                      :items="expanded[type] ? results[type] : (results[type] && results[type].slice(0, 5))"
                      @row-clicked="(item) => handleRowClick(item, type)"
                      class="result-table"
                    />
                    <v-btn
                      v-if="results[type] && results[type].length > 5"
                      color="success"
                      @click="toggleExpand(type)"
                      class="mt-2"
                      outlined
                    >
                      {{ expanded[type] ? 'Show less' : `Show all ${results[type].length} results` }}
                    </v-btn>
                    <v-alert v-if="!loading && (!results[type] || results[type].length === 0)" type="success" outlined>
                      No results found for {{ type }}
                    </v-alert>
                  </template>
                  <template v-if="['text_submissions', 'file_submissions', 'youtube_transcript_submissions'].includes(type)">
                    <submission-summary-table
                      :items="expanded[type] ? results[type] : (results[type] && results[type].slice(0, 5))"
                      :loading="loading"
                      :type="getSubmissionType(type)"
                      @row-clicked="(item) => handleRowClick(item, type)"
                      class="result-table"
                    />
                  </template>
                </v-card-text>
              </v-expand-transition>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import { mapState } from "vuex"
import IncidentSummaryTable from "@/incident/IncidentSummaryTable.vue"
import CaseSummaryTable from "@/case/CaseSummaryTable.vue"
import TaskSummaryTable from "@/task/TaskSummaryTable.vue"
import SourceSummaryTable from "@/data/source/SourceSummaryTable.vue"
import QuerySummaryTable from "@/data/query/QuerySummaryTable.vue"
import DocumentSummaryTable from "@/document/DocumentSummaryTable.vue"
import TagSummaryTable from "@/tag/TagSummaryTable.vue"
import CommandSummaryTable from "@/data/command/CommandSummaryTable.vue"
import CommitSummaryTable from "@/commit/CommitSummaryTable.vue"
import SignalInstanceSummaryTable from "@/signal/SignalInstanceSummaryTable.vue"
import WeblinkSummaryTable from "@/data/weblink/WeblinkSummaryTable.vue"
import WebcrawlSummaryTable from "@/webcrawl/WebcrawlSummaryTable.vue"
import ConversationSummaryTable from "@/conversation/ConversationSummaryTable.vue"
import ConferenceSummaryTable from "@/conference/ConferenceSummaryTable.vue"
import TicketSummaryTable from "@/ticket/TicketSummaryTable.vue"
import ECCSProjectSummaryTable from "@/websocket/ECCSProjectSummaryTable.vue"
import SubmissionSummaryTable from "@/user_submissions/SubmissionSummaryTable.vue"

export default {
  name: "OldSchoolSearchEntryPage",
  components: {
    IncidentSummaryTable,
    CaseSummaryTable,
    TaskSummaryTable,
    SourceSummaryTable,
    QuerySummaryTable,
    DocumentSummaryTable,
    TagSummaryTable,
    CommandSummaryTable,
    CommitSummaryTable,
    SignalInstanceSummaryTable,
    WeblinkSummaryTable,
    WebcrawlSummaryTable,
    ConversationSummaryTable,
    ConferenceSummaryTable,
    TicketSummaryTable,
    ECCSProjectSummaryTable,
    SubmissionSummaryTable, // Add this line
  },
  data() {
    return {
      expanded: {},
      resultTypes: [
        'incidents', 'cases', 'tasks', 'sources', 'queries', 'documents',
        'tags', 'commands', 'commits', 'signal_instances', 'weblinks', 'webcrawls', 'conversations',
        'conferences', 'tickets', 'eccs_projects',
        'text_submissions', 'file_submissions', 'youtube_transcript_submissions',
      ],
    }
  },
  computed: {
    ...mapState("search", ["results", "query", "loading"]),
    queryString: {
      get() {
        return this.$store.state.query.q
      },
      set(query) {
        this.$store.dispatch("search/setQuery", query)
      },
    },
    resultCounts() {
      const counts = {}
      for (const [type, items] of Object.entries(this.results)) {
        counts[type] = items ? items.length : 0
      }
      return counts
    },
  },
  methods: {
    toggleExpand(type) {
      this.$set(this.expanded, type, !this.expanded[type]);
    },
    handleRowClick(item, type) {
      const typeMap = {
        'incidents': 'Incident',
        'cases': 'Case',
        'tasks': 'Task',
        'sources': 'Source',
        'queries': 'Query',
        'documents': 'Document',
        'tags': 'Tag',
        'commands': 'Command',
        'commits': 'Commit',
        'signal_instances': 'SignalInstance',
        'weblinks': 'Weblink',
        'webcrawls': 'Website',
        'conversations': 'Conversation',
        'conferences': 'Conference',
        'tickets': 'Ticket',
        'eccs_projects': 'ECCSProject',
      };

      const correctedType = typeMap[type] || type.charAt(0).toUpperCase() + type.slice(1, -1);

      this.$emit('object-selected', { ...item, type: correctedType });
    },
    performSearch() {
      this.$store.dispatch("search/getResults", this.$store.state.query)
    },
    getComponentName(type) {
      const componentMap = {
        incidents: 'IncidentSummaryTable',
        cases: 'CaseSummaryTable',
        tasks: 'TaskSummaryTable',
        sources: 'SourceSummaryTable',
        queries: 'QuerySummaryTable',
        documents: 'DocumentSummaryTable',
        tags: 'TagSummaryTable',
        commands: 'CommandSummaryTable',
        commits: 'CommitSummaryTable',
        signal_instances: 'SignalInstanceSummaryTable',
        weblinks: 'WeblinkSummaryTable',
        webcrawls: 'WebcrawlSummaryTable',
        conversations: 'ConversationSummaryTable',
        conferences: 'ConferenceSummaryTable',
        tickets: 'TicketSummaryTable',
        eccs_projects: 'ECCSProjectSummaryTable',
        text_submissions: 'SubmissionSummaryTable',
        file_submissions: 'SubmissionSummaryTable',
        youtube_transcript_submissions: 'SubmissionSummaryTable',
      };
      return componentMap[type] || `${type}-summary-table`;
    },
    getSubmissionType(type) {
      const typeMap = {
        text_submissions: 'text',
        file_submissions: 'file',
        youtube_transcript_submissions: 'youtube',
      };
      return typeMap[type] || '';
    },
  },
}
</script>

<style scoped>
.v-card-text {
  max-height: 400px;
  overflow-y: auto;
}

.result-table ::v-deep tbody tr {
  position: relative;
  transition: all 0.3s ease;
  cursor: pointer;
}

.result-table ::v-deep tbody tr:hover {
  background-color: rgba(0, 0, 0, 0.05);
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
}

.result-table ::v-deep tbody tr::after {
  content: '\F0765';
  font-family: 'Material Design Icons';
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  opacity: 0;
  transition: opacity 0.3s ease;
  color: #FF1493;
}

.result-table ::v-deep tbody tr:hover::after {
  opacity: 1;
}

@keyframes pulse {
  0% {
    transform: translateY(-50%) scale(1);
  }
  50% {
    transform: translateY(-50%) scale(1.5);
  }
  100% {
    transform: translateY(-50%) scale(1);
  }
}

.result-table ::v-deep tbody tr:hover::after {
  animation: pulse 1.5s infinite;
}
</style>

<!--
.. Modified by Kishore Jalleda
.. full list of modifications at https://github.com/unstructai
.. copyright: (c) 2023 Kishore Jalleda
.. author:: Kishore Jalleda <kjalleda@gmail.com>
-->
<template>
    <div>
      <v-chip small class="ml-2">
        <v-icon color="secondary" small left>mdi-police-badge</v-icon>
        {{ typeName }}
      </v-chip>
    </div>
  </template>

  <script>
  export default {
    name: "IncidentType",

    props: {
      typeName: {
        type: String,
        required: true,
      },
      typeColor: {
        type: String,
        required: false,
      },
    },
  }
  </script>

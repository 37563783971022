<!--
.. Added by Kishore Jalleda
.. full list of modifications at https://github.com/unstructai
.. copyright: (c) 2024 Kishore Jalleda
.. author:: Kishore Jalleda <kjalleda@gmail.com>
-->
<template>
    <div>
      <v-data-table :headers="headers" :items="items">
        <template v-slot:item.project.name="{ item }">
          <v-chip small :color="item.project.color" text-color="white">
            {{ item.project.name }}
          </v-chip>
        </template>
        <template v-slot:item.data-table-actions="{ item }">
          <v-menu bottom left>
            <template v-slot:activator="{ on }">
              <v-btn icon v-on="on">
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item :to="{ name: 'QueryTableEdit', params: { name: item.name } }">
                <v-list-item-title>View / Edit</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
    </div>
  </template>

  <script>
  import { mapActions } from "vuex"
  export default {
    name: "QuerySummaryTable",

    components: {},
    data() {
      return {
        headers: [
          { text: "Name", value: "name", sortable: true },
          { text: "Project", value: "project.name", sortable: false },
          { text: "Description", value: "description", sortable: false },
          { text: "Language", value: "language", sortable: true },
          {
            text: "",
            value: "data-table-actions",
            sortable: false,
            align: "end",
          },
        ],
      }
    },

    props: {
      items: {
        default: function () {
          return []
        },
        type: Array,
      },
      loading: {
        default: function () {
          return false
        },
        type: [String, Boolean],
      },
    },

    methods: {
      ...mapActions("query", ["removeShow"]),
    },
  }
  </script>

<!--
.. Modified by Kishore Jalleda
.. full list of modifications at https://github.com/unstructai
.. copyright: (c) 2023 Kishore Jalleda
.. author:: Kishore Jalleda <kjalleda@gmail.com>
-->
<template>
  <div v-if="priority == 'Low'">
    <v-chip small class="ma-2">
      <v-icon left color="success">mdi-flag-outline</v-icon>
      {{ priority }}
    </v-chip>
  </div>
  <div v-else-if="priority == 'Medium'">
    <v-chip small class="ma-2">
      <v-icon left color="warning">mdi-flag-outline</v-icon>
      {{ priority }}
    </v-chip>
  </div>
  <div v-else-if="priority == 'High'">
    <v-chip small class="ma-2">
      <v-icon left color="error">mdi-flag-outline</v-icon>
      {{ priority }}
    </v-chip>
  </div>
  <div v-else-if="priority == 'Critical'">
    <v-chip small class="ma-2">
      <v-icon left color="purple">mdi-flag-outline</v-icon>
      {{ priority }}
    </v-chip>
  </div>
  <div v-else>
    <v-chip small class="ma-2">
      <v-icon left color="grey">mdi-flag-outline</v-icon>
      {{ priority }}
    </v-chip>
  </div>
</template>

<script>
export default {
  name: "CasePriority",

  props: {
    priority: {
      type: String,
      required: true,
    },
  },
}
</script>

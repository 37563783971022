<template>
    <div>
      <v-data-table
        :headers="headers"
        :items="items"
        :loading="loading"
        @click:row="handleRowClick"
      >
        <template v-slot:item.id="{ item }">
          <v-icon
            :class="{'copied-icon': copiedItemId === item.id}"
            small
            left
            @click="copyToClipboard(item.id)"
          >
            mdi-content-copy
          </v-icon>
          {{ item.id }}
        </template>
        <template v-slot:item.status="{ item }">
          <v-chip :color="getStatusColor(item.status)" small>
            {{ item.status }}
          </v-chip>
        </template>
        <template v-slot:item.created_at="{ item }">
          {{ formatDate(item.created_at) }}
        </template>
        <template v-slot:item.updated_at="{ item }">
          {{ formatDate(item.updated_at) }}
        </template>
        <template v-slot:item.data-table-actions="{ item }">
          <v-menu bottom left>
            <template v-slot:activator="{ on }">
              <v-btn icon v-on="on">
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item @click="viewDetails(item)">
                <v-list-item-title>View Details</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
    </div>
  </template>

  <script>
  import { mapActions } from "vuex";

  export default {
    name: "SubmissionSummaryTable",
    data() {
      return {
        headers: [
          { text: "ID", value: "id", align: "left", sortable: true },
          { text: "Status", value: "status", align: "left", sortable: true },
          { text: "Created At", value: "created_at", align: "left", sortable: true },
          { text: "Updated At", value: "updated_at", align: "left", sortable: true },
          { text: "Project ID", value: "eccs_project_id", align: "left", sortable: true },
          { text: "Retry Count", value: "retry_count", align: "left", sortable: true },
          { text: "", value: "data-table-actions", sortable: false, align: "end" },
        ],
        copiedItemId: null,
      };
    },
    props: {
      items: {
        default: () => [],
        type: Array,
      },
      loading: {
        default: false,
        type: [String, Boolean],
      },
      type: {
        type: String,
        required: true,
        validator: (value) => ['text', 'file', 'youtube'].includes(value),
      },
    },
    computed: {
      typeSpecificHeaders() {
        switch (this.type) {
          case 'text':
            return [
              { text: "Name", value: "name", align: "left", sortable: true },
            ];
          case 'file':
            return [
              { text: "File Name", value: "file_name", align: "left", sortable: true },
              { text: "File Type", value: "file_type", align: "left", sortable: true },
            ];
          case 'youtube':
            return [
              { text: "Video ID", value: "video_id", align: "left", sortable: true },
              { text: "Video Title", value: "video_title", align: "left", sortable: true },
            ];
          default:
            return [];
        }
      },
    },
    created() {
      this.headers = [...this.typeSpecificHeaders, ...this.headers];
    },
    methods: {
      ...mapActions("submission", ["showDetails"]),
      copyToClipboard(text) {
        navigator.clipboard.writeText(text).then(() => {
          this.copiedItemId = text;
          setTimeout(() => {
            this.copiedItemId = null;
          }, 2000);
        });
      },
      handleRowClick(item) {
        this.$emit('row-clicked', item);
      },
      viewDetails(item) {
        this.showDetails(item);
      },
      formatDate(date) {
        return new Date(date).toLocaleString();
      },
      getStatusColor(status) {
        const statusColors = {
          'pending': 'orange',
          'processing': 'blue',
          'completed': 'green',
          'failed': 'red',
        };
        return statusColors[status.toLowerCase()] || 'grey';
      },
    },
  };
  </script>

  <style scoped>
  .copied-icon {
    color: green;
  }
  </style>

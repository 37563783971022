<template>
    <v-select
      v-model="taskTypes"
      :items="items"
      :menu-props="{ maxHeight: '400' }"
      :label="label"
      clearable
      multiple
      chips
    />
  </template>

  <script>
  import { cloneDeep } from "lodash"
  export default {
    name: "TaskTypeMultiSelect",
    props: {
      value: {
        type: Array,
        default: function () {
          return []
        },
      },
      label: {
        type: String,
        default: function () {
          return "Type"
        },
      },
      project: {
        type: String,
        required: false,
        default: function () {
          return ""
        },
      },
    },
    data() {
      return {
        items: ["Task", "Follow-up"],
      }
    },
    computed: {
      taskTypes: {
        get() {
          return cloneDeep(this.value)
        },
        set(value) {
          this.$emit("input", value)
        },
      },
    },
  }
  </script>

<!--
.. Modified by Kishore Jalleda
.. full list of modifications at https://github.com/unstructai
.. copyright: (c) 2023 Kishore Jalleda
.. author:: Kishore Jalleda <kjalleda@gmail.com>
-->
<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="items"
      :loading="loading"
      @click:row="handleRowClick"
    >
      <template v-slot:item.incident_priority.name="{ item }">
        <incident-priority
          :priorityName="item.incident_priority.name"
          :priorityColor="item.incident_priority.color"
        />
      </template>
      <template v-slot:item.id="{ item }">
        <v-icon
          :class="{'copied-icon': copiedItemId === item.id}"
          small
          left
          @click="copyToClipboard(item.id)"
        >
          mdi-content-copy
        </v-icon>
        {{ item.id }}
      </template>
      <template v-slot:item.status="{ item }">
        <incident-status :status="item.status" :id="item.id" />
      </template>
      <template v-slot:item.incident_type.name="{ item }">
        <incident-type :typeName="item.incident_type.name" />
      </template>
      <template v-slot:item.commander="{ item }">
        <incident-participant :participant="item.commander" />
      </template>
      <template v-slot:item.reporter="{ item }">
        <incident-participant :participant="item.reporter" />
      </template>
      <template v-slot:item.project.name="{ item }">
        <project-card :project="item.project" />
      </template>
      <template v-slot:item.reported_at="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <span v-bind="attrs" v-on="on">{{ item.reported_at | formatRelativeDate }}</span>
          </template>
          <span>{{ item.reported_at | formatDate }}</span>
        </v-tooltip>
      </template>
      <template v-slot:item.data-table-actions="{ item }">
        <v-menu bottom left>
          <template v-slot:activator="{ on }">
            <v-btn icon v-on="on">
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item :to="{ name: 'IncidentTableEdit', params: { name: item.name } }">
              <v-list-item-title>View / Edit</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
    </v-data-table>
  </div>
</template>
<script>
import { mapActions } from "vuex"

import IncidentParticipant from "@/incident/Participant.vue"
import IncidentPriority from "@/incident/priority/IncidentPriority.vue"
import IncidentStatus from "@/incident/status/IncidentStatus.vue"
import ProjectCard from "@/project/ProjectCard.vue"
import IncidentType from "@/incident/type/IncidentType.vue"

export default {
  name: "IncidentSummaryTable",

  components: {
    IncidentPriority,
    IncidentStatus,
    IncidentParticipant,
    ProjectCard,
    IncidentType,
  },

  data() {
    return {
      headers: [
        { text: "Name", value: "name", align: "left", width: "10%", sortable: true },
        { text: "ID", value: "id", align: "left", width: "10%", sortable: true },
        { text: "Title", value: "title", sortable: false },
        { text: "Status", value: "status" },
        { text: "Type", value: "incident_type.name" },
        { text: "Priority", value: "incident_priority.name", width: "10%" },
        { text: "Project", value: "project.name", sortable: true },
        { text: "Commander", value: "commander", sortable: false },
        { text: "", value: "data-table-actions", sortable: false, align: "end" },
      ],
      copiedItemId: null,
    }
  },

  props: {
    items: {
      default: function () {
        return []
      },
      type: Array,
    },
    loading: {
      default: function () {
        return false
      },
      type: [String, Boolean],
    },
  },

  methods: {
    ...mapActions("incident", ["showEditSheet", "joinIncident"]),
    copyToClipboard(text) {
      navigator.clipboard.writeText(text).then(() => {
        this.copiedItemId = text
        setTimeout(() => {
          this.copiedItemId = null
        }, 2000)
      })
    },
    handleRowClick(item) {
      this.$emit('row-clicked', item);
    },
  },
}
</script>

<style scoped>
.copied-icon {
  color: green;
}
</style>

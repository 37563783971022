<!--
.. Added by Kishore Jalleda
.. full list of modifications at https://github.com/unstructai
.. copyright: (c) 2024 Kishore Jalleda
.. author:: Kishore Jalleda <kjalleda@gmail.com>
-->
<template>
  <div>
    <v-dialog max-width="600px" v-model="dialog">
      <template v-slot:activator="{ on, attrs }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on: tooltipOn }">
            <v-btn icon v-bind="attrs" v-on="{ ...on, ...tooltipOn }" color="primary" dark>
              <v-icon>mdi-auto-fix</v-icon>
            </v-btn>
          </template>
          <span>View Commit Entity Extract</span>
        </v-tooltip>
      </template>

      <v-card>
        <v-card-title>
          <v-icon left>mdi-auto-fix</v-icon>
          Raw Commit Entity Extract
        </v-card-title>

        <v-card-text>
          <v-expansion-panels popout>
            <v-expansion-panel v-for="(value, key) in commit_raw" :key="key">
              <v-expansion-panel-header>{{ key }}</v-expansion-panel-header>
              <v-expansion-panel-content>
                <div v-if="Array.isArray(value)">
                  <ul>
                    <li v-for="(item, index) in value" :key="index">{{ item }}</li>
                  </ul>
                </div>
                <div v-else-if="typeof value === 'object' && value !== null">
                  <div v-for="(subValue, subKey) in value" :key="subKey">
                    {{ subKey }}: {{ subValue }}
                  </div>
                </div>
                <div v-else>
                  {{ value }}
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-card-text>

        <v-card-actions>
          <v-btn icon @click="onLike">
            <v-icon>mdi-thumb-up-outline</v-icon>
          </v-btn>
          <v-btn icon @click="onDislike">
            <v-icon>mdi-thumb-down-outline</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="dialog = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

  <script>
  export default {
    props: {
      commit_raw: {
        type: Object,
        default: () => ({}),
      }
    },
    data() {
      return {
        dialog: false
      };
    },
    methods: {
      onLike() {
        this.$emit('like');
        this.dialog = false;
      },
      onDislike() {
        this.$emit('dislike');
        this.dialog = false;
      }
    },
  }
  </script>

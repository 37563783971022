<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="items"
      @click:row="handleRowClick"
      >
      <template v-slot:item.id="{ item }">
        <v-icon
          :class="{'copied-icon': copiedItemId === item.id}"
          small
          left
          @click="copyToClipboard(item.id)"
        >
          mdi-content-copy
        </v-icon>
        {{ item.id }}
      </template>
      <template v-slot:item.description="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <div v-on="on">{{ truncateText(item.description, 50) }}</div>
          </template>
          <span> {{ item.description }} </span>
        </v-tooltip>
      </template>
    </v-data-table>
  </div>
</template>

<script>

import { mapActions } from "vuex";

export default {
  name: "WebcrawlSummaryTable",

  data() {
    return {
      headers: [
        { text: "Name", value: "name", sortable: true },
        { text: "ID", value: "id", sortable: true },
        { text: "Project", value: "project.name", sortable: false },
        { text: "Description", value: "description", sortable: false },
        { text: "URL", value: "url", sortable: false },
      ],
      copiedItemId: null,
    };
  },

  props: {
    items: {
      default: function () {
        return [];
      },
      type: Array,
    },
    loading: {
      default: function () {
        return false;
      },
      type: [String, Boolean],
    },
  },

  methods: {
    copyToClipboard(text) {
      navigator.clipboard.writeText(text).then(() => {
        this.copiedItemId = text;
        setTimeout(() => {
          this.copiedItemId = null;
        }, 2000);
      });
    },
    truncateText(text, length) {
      if (text.length > length) {
        return text.substring(0, length) + "...";
      }
      return text;
    },
    handleRowClick(item) {
      this.$emit("row-clicked", item);
    },
  },
};

</script>

<style scoped>
.copied-icon {
  color: green;
}
</style>

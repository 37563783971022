<template>
  <div>
    <div v-if="status == 'New'">
      <v-chip small class="ma-2">
        <v-icon left color="error">mdi-alert-circle</v-icon>
        {{ status }}
      </v-chip>
    </div>
    <div v-if="status == 'Triage'">
      <v-chip small class="ma-2">
        <v-icon left color="warning">mdi-account-search</v-icon>
        {{ status }}
      </v-chip>
    </div>
    <div v-if="status == 'Escalated'">
      <v-chip small class="ma-2">
        <v-icon left color="primary">mdi-arrow-up</v-icon>
        {{ status }}
      </v-chip>
    </div>
    <div v-if="status == 'Closed'">
      <v-chip small class="ma-2">
        <v-icon left color="success">mdi-lock</v-icon>
        {{ status }}
      </v-chip>
    </div>
  </div>
</template>

<script>
export default {
  name: "CaseStatus",

  props: {
    status: {
      type: String,
      required: true,
    },
    id: {
      type: Number,
      required: true,
    },
  },
}
</script>
